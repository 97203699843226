<template>
    <!-- LOADER -->
    <div id="preloader">
        <div class="loader">
			<div class="loader__bar"></div>
			<div class="loader__bar"></div>
			<div class="loader__bar"></div>
			<div class="loader__bar"></div>
			<div class="loader__bar"></div>
			<div class="loader__ball"></div>
		</div>
    </div><!-- end loader -->
    <!-- END LOADER -->
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            preloader: state => state.preloader
        })
    }
}
</script>